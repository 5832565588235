import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"

import ColorBlock from "./molecules/ColorBlock"
import { theme } from "./particles/theme"

const StyledColorDisplay = styled.div`
  margin-top: 3rem;
  display: -ms-flexbox;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  grid-auto-flow: dense;

  @media (max-width: ${theme.breakpoints.desktop}) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: ${theme.breakpoints.tablet}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: ${theme.breakpoints.mobileMd}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${theme.breakpoints.mobileSm}) {
    grid-template-columns: 1fr;
  }

  h3 {
    grid-column: 1/7;
    grid-row: 6;
    text-align: center;
  }
`

const ColorComponent = (color, idx, colorInfo, sort, filter) => {
  let link = '';
  let btn_text = 'Buy Now';
  if (color.quad_max_url) {
    if (((sort == 'idh' || filter == 'idh') || color.max_unavailable) && color.quad_url) {
      link = color.quad_url
    }
    else {
      link = color.quad_max_url;
    }
  }
  else if (color.quad_url) {
    link = color.quad_url;
  }

  let tracking_code = '?cm_mmc=vd-_-c-_-prd-_-pnt-_-hnk-_-mcs-_-osi_color_match-_-0-_-0-_-0';

  return (
  <ColorBlock
    key={color.id + " " + idx}
    osi={color.osi}
    idh={color.idh}
    idhmax={color.idhmax}
    link={link + tracking_code}
    btn={color.btn_text ? color.btn_text : btn_text}
    rgb={colorInfo ? color.adjusted_rgb : color.adjusted_rgb}
    color_name={colorInfo ? color.color_name : null}
    max_unavailable={color.max_unavailable}
  />
  )
}

const filterColors = (colors, filter) => {
  switch (filter) {
    case "idh":
      return colors.filter(color => color.idh)
    case "idhmax":
      return colors.filter(color => color.idhmax)
    default:
      return colors
  }
}

const sortColors = (colors, sort) => {
  switch (sort) {
    case "colorName":
      return colors.sort((a, b) => {
        if (a.color_name < b.color_name) {
          return -1
        }
        if (a.color_name > b.color_name) {
          return 1
        }
        return 0
      })
    case "idh":
      return colors.sort((a, b) => {
        if (a.idh === "" || !a.idh) return 1
        else if (b.idh === "" || !b.idh) return -1
        else return a.idh.toString().match(/[0-9]+/) - b.idh.toString().match(/[0-9]+/) // use first idh if multiple
      })
    case "idhmax":
      return colors.sort((a, b) => {
        if (a.idhmax === "" || !a.idhmax) return 1
        else if (b.idhmax === "" || !b.idhmax) return -1
        else return a.idhmax.toString().match(/[0-9]+/) - b.idhmax.toString().match(/[0-9]+/) // use first idhmax if multiple
      })
    default:
      return colors.sort((a, b) => a.osi - b.osi)
  }
}

const removeDups = (colors) => {
  let osiList = [];
  let dedupColors = [];
  colors.forEach(color => {
    if(!osiList.includes(color.osi)) {
    osiList.push(color.osi);
    dedupColors.push(color);
    }
    else {
      dedupColors.filter(old_color => {
        if(old_color.osi === color.osi) {
          if(color.idh) {
            old_color.idh += old_color.idh ? ', ' + color.idh : color.idh
            color.idh = ''
            old_color.quad_url  += old_color.quad_url ? ', ' + color.quad_url : color.quad_url
            color.quad_url = ''
          }
          if(color.idhmax) {
            old_color.idhmax += old_color.idhmax ? ', ' + color.idhmax : color.idhmax
            color.idhmax = ''
            old_color.quad_max_url += old_color.quad_max_url ? ', ' + color.quad_max_url : color.quad_max_url
            color.quad_max_url = ''
          }

        }
        return old_color
    })
  }
  return color
  });
  return dedupColors;
}

const findUnavailable = (colors, manufacturers) => {
  let unavailable = [];
  colors.forEach(color => {
      if(color.idhmax && !parseInt(color.available)) {
        color.max_unavailable = true;
        unavailable.push(color);
      }
    return color
  })

  // put unavailable colors to the end
  unavailable.forEach(uColor => {
    colors = colors.filter(color => color !== uColor)
  })
  unavailable.forEach(uColor => colors.push(uColor))

  return colors;
}

const ShowColors = ({colorInfo, osi, idhNum, idhmaxNum, sort, filter, manufacturer, colorName}) => {
  const { allMysqlIdh2Lowes } = useStaticQuery(graphql`
    query AllColors {
      allMysqlIdh2Lowes {
        nodes {
          osi
          idh
          idhmax
          quad_url
          quad_max_url
          adjusted_rgb
          available
        }
      }
    }
  `)
//unavailable
  let colors;
  colors = colorInfo ? colorInfo.color_cross_refs : allMysqlIdh2Lowes.nodes
  console.log(colors);

  colors = filterColors(colors, filter)
  colors = sortColors(colors, sort)
  if(manufacturer === "Show All") {
    colors = removeDups(colors)
  }
  else {
    if(colorName && colorName !== `Show All`) {
      colors = colors.filter(color => color.color_name === colorName)
    }
  }
  colors = findUnavailable(colors)

  let isColor = 0

  return (
    <StyledColorDisplay>
      {colors.map((color, idx) => {
        if (osi && color.osi.match(`^${osi}`)) {
          isColor = 1
          return ColorComponent(color, idx, colorInfo, sort, filter)
        } else if (idhNum && color.idh) {
          if (color.idh.toString().match(`^${idhNum}`)) {
            isColor = 1
            return ColorComponent(color, idx, colorInfo, sort, filter)
          }
        } else if (idhmaxNum && color.idhmax) {
           if (color.idhmax.toString().match(`^${idhmaxNum}`)) {
            isColor = 1
            return ColorComponent(color, idx, colorInfo, sort, filter)
           }
        }
         else if (!idhmaxNum && !osi && !idhNum) {
          isColor = 1
          return ColorComponent(color, idx, colorInfo, sort, filter)
        } else {
          return null
        }
        return null // never called; for linter
      })}
      {!isColor ? <h3>No Results to display</h3> : undefined}
    </StyledColorDisplay>
  )
}

export default ShowColors
