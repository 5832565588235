import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/templates/layout"
import SEO from "../components/seo"
import MainPage from "../components/templates/MainPage"

export const query = graphql`
  query MyQuery2 {
    allMysqlManufacturers(sort: {fields: name}) {
      nodes {
        name
        color_cross_refs {
          adjusted_rgb
          color_name
          idh
          idhmax
          osi
          quad_url
          quad_max_url
          available
        }
      }
    }
  }
`

const IndexPage = ({ data }) => {

  let manufacturers = data.allMysqlManufacturers.nodes;
  let dedupColors = [];
  let colorNameList = [];

  // remove duplicate colors from each manufacturer, then sort by color_name
  manufacturers.forEach(manufacturer => {
    dedupColors = [];
    colorNameList = [];
    manufacturer.color_cross_refs.forEach(color => {
      if(!colorNameList.includes(color.color_name)) {
        colorNameList.push(color.color_name);
        dedupColors.push(color)
      }
    })
    dedupColors = dedupColors.sort((a, b) => {
      if(a.color_name < b.color_name) {return -1;}
      if(a.color_name > b.color_name) {return 1;}
      return 0;
    })
    manufacturer.color_cross_refs = dedupColors;
  });

  return (
  <Layout>
    <SEO title="Color Match Tool" />
        <MainPage manufacturers={data.allMysqlManufacturers.nodes} />
  </Layout>
  )
}

export default IndexPage
